/**
 * A helper for working with Next.js `getStaticProps`.
 *
 * @example
 * export const getStaticProps = withStaticProps(() => {
 *  return {
 *    props: {
 *      message: 'Hello World!',
 *    },
 *  };
 * });
 */

import { GetStaticPropsContext, GetStaticPropsResult } from 'next';

type Fn = (context: GetStaticPropsContext) => GetStaticPropsResult<Record<string, unknown>>;
interface Options {
	revalidate?: number;
}

export function withStaticProps(fn: Fn, options: Options = {}) {
	return async (context: GetStaticPropsContext): Promise<GetStaticPropsResult<Record<string, unknown>>> => {
		const pageSettings = await fn(context);

		return {
			...pageSettings,

			/**
			 * Re-build this static page asset after this TTL (seconds).
			 * If not set, this page will stay static until the next deployment.
			 *
			 * @see
			 * https://nextjs.org/docs/basic-features/data-fetching#incremental-static-regeneration
			 * https://vercel.com/docs/v2/edge-network/caching#stale-while-revalidate
			 * https://github.com/zeit/next.js/discussions/11552
			 *
			 * The re-build happens in the background by Vercel and doesn't affect the
			 * response to the client, as it will always serve the asset from the CDN.
			 * Once the re-build is done, the CDN asset is updated and future requests
			 * will get the latest version.
			 * There's currently no reason for us to set a TTL higher than 1 second.
			 */
			revalidate: options.revalidate ?? 1,
		};
	};
}
