import { GetStaticProps } from 'next';
import { Container, Grid, Typography } from '@material-ui/core';
import { css } from '@emotion/react';

import Page from 'layouts/Page';

import { useColors } from 'hooks/useColors';
import { IColors } from 'types/colors';
import { withStaticProps } from 'lib/withStaticProps';

// import { colors } from 'styles/theme';
import { tabletUp, heroDescriptionCss } from 'styles/utils';

// import Link from 'components/shared/Link/Link';
import Picture from 'components/shared/Picture/Picture';
import DynamicSearch from 'components/shared/Search/DynamicSearch';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import {QueryKeys} from "../../constants";
import {ErrorBanner} from "components/HomePage/components";

const heroCss = (colors: IColors) => css`
	color: ${tabletUp ? colors.tx_white : colors.tx_text_primary};
	padding-top: 50px;
	min-height: 450px;
`;

/* interface Props {
  message: string;
  title: string;
}*/

// function HomePage(props: Props) {
function HomePage() {
	const { colors } = useColors();
	const router = useRouter();

	const [isInvitationTokenInvalid, setInvitationTokenInvalid] = useState(false);

	useEffect(() => {
		if (router.query[QueryKeys.InvalidInvitationToken]) {
			setInvitationTokenInvalid(true);
		}
	}, [router.query]);

	const closeError = useCallback(() => {
		setInvitationTokenInvalid(false);
	}, []);

	return (
		<Page showHeroBg>
			<ErrorBanner
				message={'Invitation token is invalid or expired.'}
				title={'Error'}
				onClose={closeError}
				hidden={!isInvitationTokenInvalid}
			/>

			<Container css={heroCss(colors)} maxWidth="lg">
				<Grid container justify="space-between" spacing={4}>
					<Grid item sm={12} md={5}>
						<div>
							<Picture isModule path="home-slogan.png" alt="Bond Sports slogan" width="260" />
							<Typography css={heroDescriptionCss(colors)} variant="body1">
								The easy way for sports organizers to book a space for their activities! Bond Sports helps you find and
								book local facilities. The only curated and verified national directory of local sports facilities.
							</Typography>
						</div>
					</Grid>
					<Grid item sm={12} md={7}>
						<DynamicSearch />
					</Grid>
				</Grid>
			</Container>
			{/* <Container maxWidth="lg">
        <h1>
          {props.title} - {props.message}
        </h1>
        <p>
          Example Facility Page:{' '}
          <Link href="/facility/monroe-sports-center-monroe-township/240">
            /facility/monroe-sports-center-monroe-township/240
          </Link>
        </p>
        <p>
          Example Program Page:{' '}
          <Link href="/activity/programs/male-youth-basketball-monroe-township/1">
            /activity/programs/male-youth-basketball-monroe-township/1
          </Link>
        </p>
        <p>
          Example League Page:{' '}
          <Link href="/activity/leagues/as/1">/activity/leagues/as/1</Link>
        </p>
      </Container>*/}
		</Page>
	);
}

export const getStaticProps: GetStaticProps = withStaticProps(() => {
	/*
	 * small demo on how to properly use and load static props for SSR
	 * */

	// fake fetch
	// const res = await fetch('www.fetchmydata.com')
	const res = {
		data: {
			message: 'HOWDY!',
			title: 'Home PAAAAGE!',
		},
	};

	return {
		props: {
			message: res.data.message,
			title: res.data.title,
		},
	};
});

export default HomePage;
