import dynamic from 'next/dynamic';

const DynamicSearch = dynamic(
	() =>
		import(
			/* webpackChunkName: "DynamicSearch" */
			'components/shared/Search/Search'
		),
	{
		ssr: false,
	}
);

export default DynamicSearch;
