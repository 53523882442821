// eslint-disable-next-line no-restricted-imports
import {Alert, AlertTitle} from "@material-ui/lab";
import {css} from "@emotion/react";

interface ErrorBannerProps {
	readonly hidden: boolean;
	readonly message: string;
	readonly title: string;
	readonly onClose: () => void;
}

const alertCss = css`
	margin: 0 0 20px 0;
`;

export function ErrorBanner({ title, message, hidden, onClose }: ErrorBannerProps) {
	return (
		<Alert
			severity="error"
			variant="filled"
			css={alertCss}
			hidden={hidden}
			onClose={onClose}
		>
			<AlertTitle>{title}</AlertTitle>
			{message}
		</Alert>
	);
}
